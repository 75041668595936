import { createApp } from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import globalMixin from './utils/acl.js'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles.css'
import './bootstrap.css'
import locale from 'element-plus/lib/locale/lang/fr'
import 'dayjs/locale/fr'
import mitt from 'mitt'

import {
  Delete,
  Edit,
  Setting,
  Plus,
  InfoFilled,
  Search,
  Message,
  User,
  MoreFilled,
  Operation,
} from '@element-plus/icons-vue'
//import axios from "axios";
const eventBus = mitt()
//export api_base_url=""
const app = createApp({ extends: App, mixins: [globalMixin] })

app.config.globalProperties = {
  $bus: eventBus,
  DeleteIcon: Delete,
  EditIcon: Edit,
  UserIcon: User,
  SettingIcon: Setting,
  PlusIcon: Plus,
  InfoIcon: InfoFilled,
  SearchIcon: Search,
  MessageIcon: Message,
  MoreField: MoreFilled,
  Operation: Operation,
}
app.use(VueSweetalert2)
app.use(ElementPlus, { locale }).use(store).use(router).mount('#app')
