<template>
  <router-view />
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  created() {},
  mounted() {
    this.getFromStorage()
  },
  methods: {
    getFromStorage() {
      let user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        this.$store.commit('auth/SET_USER', user)
        let token = 'Bearer ' + user.token
        this.$store.commit('auth/SET_TOKEN', token)
      } else {
        this.$router.push('/login')
      }
    },
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
