<template>
  <el-container>
    <el-aside width="auto">
      <sidebar-app />
    </el-aside>
    <el-container direction="vertical">
      <header-app />
      <main-app />
      <footer-app />
    </el-container>
  </el-container>
</template>

<script>
import FooterApp from './FooterApp.vue'
import HeaderApp from './HeaderApp.vue'
import MainApp from './MainApp.vue'
import SidebarApp from './SidebarApp.vue'

export default {
  components: { SidebarApp, MainApp, FooterApp, HeaderApp },
  data() {
    return {
      isCollapse: true,
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style>
body {
  margin: 0;
}
.el-container {
  min-height: 100%;
}
.el-header {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e6e6e6;
}
.el-footer {
  border-top: solid 1px #e6e6e6;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 100%;
}
</style>
