<template>
  <el-main><router-view :key="key" /></el-main>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
}
</script>

<style></style>
