import request from '@/utils/request'
import _ from 'lodash'
import axios from 'axios'
export default {
  namespaced: true,
  state: {
    retailers: [],
    retailer: null,
    index: null,
  },
  getters: {
    retailers(state) {
      return state.retailers
    },
    index(state) {
      return state.index
    },
    retailer(state) {
      return state.retailer
    },
  },
  mutations: {
    SET_RETAILERS(state, retailers) {
      state.retailers = retailers
    },
    SET_USER(state, retailer) {
      state.retailer = retailer
    },
    SET_INDEX(state, index) {
      state.index = index
    },
  },
  actions: {


    addRetailer({ dispatch }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/retailers`,
        method: 'post',
        params,
        headers,
        data,
      })
        .then(() => {
          dispatch('getRetailers', {})
        })
    },

    updateRetailer({ dispatch }, {
      id = null,
      params = {},
      headers = {},
      data = {},
    }) {
      return request({
        url: `/retailers/${id}`,
        method: 'put',
        params,
        headers,
        data,
      })
        .then(() => {
          dispatch('getRetailers', {})
        })
    },
    setRetailer({ commit }, data) {
      commit('SET_USER', data)
    },

    editRetailerApi(data) {
      return new Promise((res, rej) => {
        axios('/api/v1/admin/memberships/' + data.id, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: data.token,
          },
          data: data,
          params: {
            organization_id: data.organization_id,
          },
        })
          .then((response) => {
            res(response)
          })
          .catch((error) => rej(error))
      })
    },

    deleteRetailer({ dispatch }, {
      id = null,
      params = {},
      headers = {},
      data = {},
    }) {
      return request({
        url: `/retailers/${id}`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then(() => {
          dispatch('getRetailers', {})
        })
        .catch((error) => console.log(error))
    },









    getRetailers({ commit }, { params = {}, headers = {}, data = {} }) {
      let defaultParams = {
        sort: 'id,desc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/retailers`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('SET_RETAILERS', response.data)
          return response.data
        })
    }


  },
}
