import _ from 'lodash'


const globalMixin = {
  created() { },
  mounted() { },
  computed: {
    userId() {
      return JSON.parse(localStorage.getItem('user'))?.id ?? null
    },
    user() {
      return JSON.parse(localStorage.getItem('user')) || null
    },
    retailerId() {
      return JSON.parse(localStorage.getItem('user'))?.retailer?.id ?? null
    },
    retailerSlug() {
      return JSON.parse(localStorage.getItem('user'))?.retailer?.slug ?? null
    },
    isAppAdmin() {
      return this.hasRole('APP_ADMIN')
    },
    isAppUser() {
      return this.hasAnyRole(['APP_ADMIN', 'APP_USER'])
    },
    isAdmin() {
      return this.hasRole('RETAILER_ADMIN')
    },
    isManager() {
      return this.hasRole('RETAILER_MANAGER')
    },
    isBackendUser() {
      return this.hasAnyRole([
        'APP_ADMIN',
        'APP_ADMIN',
        'RETAILER_ADMIN',
        'RETAILER_MANAGER',
      ])
    },
    mapBoxKey() {
      return 'pk.eyJ1Ijoic2xpbW91aWNodGF0aSIsImEiOiJjamsydm1rb3Mwd2pnM3Fud2I1ZTludTc1In0.mAjNZIG0yJYQy4-jKS5QAQ'
    },
  },
  methods: {
    hasRole(role) {
      return _.includes(this.user.roles, role)
    },
    hasAnyRole(roles) {
      return _.intersection(this.user.roles, roles).length
    },
    hasAllRoles(roles) {
      return !_.difference(this.user.roles, roles).length
    },
  },
}

export default globalMixin
