import _ from 'lodash'

import request from '../../utils/request.js'
export default {
  namespaced: true,
  state: {
    users: [],
    filtredUsers: [],
    invitations: [],
    metadata: null,
    user: null,
    index: null,
  },
  getters: {
    users(state) {
      return state.users
    },
    index(state) {
      return state.index
    },
    metadata(state) {
      return state.metadata
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    SET_USER(state, user) {
      state.user = user
    },
    setFiltredUsers(state, data) {
      state.filtredUsers = data
    },
    SET_INDEX(state, index) {
      state.index = index
    },
    SET_META(state, meta) {
      state.metadata = meta
    },

  },
  actions: {

    setIndex({ commit }, data) {
      commit('SET_INDEX', data)
    },

    getUsersByQuery({ commit }, { params = {}, headers = {} }) {
      return request({
        url: `/users/search`,
        method: 'get',
        params,
        headers,
      }).then((response) => {
        let data = response.data
        commit('setFiltredUsers', data)
        return data
      })
    },

    addUser({ dispatch }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/users/register`,
        method: 'post',
        params,
        headers,
        data,
      }).then(() => {
        dispatch('getUsers', {})
      })
    },

    updateUser(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/users/${id}`,
        method: 'put',
        params,
        headers,
        data,
      }).then((response) => {
        dispatch('getUsers', {})
        return response.data
      })
    },
    setUser({ commit }, data) {
      commit('SET_USER', data)
    },


    deleteUser(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/users/${id}`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then(() => {
          dispatch('getUsers', {})
        })
        .catch((error) => console.log(error))
    },


    getUsers({ commit }, { params = {}, headers = {}, data = {} }) {
      let defaultParams = {
        sort: 'id,desc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/users`,
        method: 'get',
        params,
        headers,
        data,
      }).then((response) => {
        commit('SET_USERS', response.data.content)
        commit('SET_META', {
          pageable: response.data.pageable,
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
        })
      })
    },
    attempt({ commit }, data) {
      var tab = []
      for (var i = 0; i < data.data.length; i++) {
        for (var j = 0; j < data.included.length; j++) {
          if (data.included[j].type == 'members') {
            if (
              data.included[j].id == data.data[i].relationships.member.data.id
            ) {
              var data2 = {
                attributes: data.data[i],
                member: data.included[j],
              }
              tab.push(data2)
            }
          }
        }
      }
      for (var k = 0; k < tab.length; k++) {
        for (j = 0; j < data.included.length; j++) {
          if (data.included[j].type == 'roles') {
            if (
              data.included[j].id ==
              tab[k].attributes.relationships.roles.data[0].id
            ) {
              tab[k]['roles'] = data.included[j]
            }
          }
        }
      }
      commit('SET_USERS', tab)
      commit('SET_META', data.meta)
    },

  },
}
