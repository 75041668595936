import request from '../../utils/request.js'
export default {
  namespaced: true,
  state: {
    list: [],
    isLoaded: false,
  },
  getters: {},
  mutations: {
    setList(state, data) {
      state.list = data
    },
  },
  actions: {
    getList({ commit }, { params = {}, headers = {} }) {
      return request({
        url: `/report-categories`,
        method: 'get',
        params,
        headers,
      }).then((response) => {
        commit('setList', response.data)
        return response.data
      })
    },

    addItem({ dispatch }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/report-categories`,
        method: 'post',
        params,
        data,
        headers,
      }).then(() => {
        dispatch('getList', {})
      })
    },

    updateItem(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/report-categories/${id}`,
        method: 'put',
        params,
        data,
        headers,
      }).then(() => {
        dispatch('getList', {})
      })
    },
    deleteItem(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/report-categories/${id}`,
        method: 'delete',
        params,
        data,
        headers,
      }).then(() => {
        dispatch('getList', {})
      })
    },
  },
}
