import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import organisations from './modules/organisations'
import devices from './modules/devices'
import users from '@/store/modules/users'
import emnify from '@/store/modules/emnify'
import retailers from '@/store/modules/retailers'
import reports from '@/store/modules/reports'
import categories from '@/store/modules/categories'

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    organisations,
    users,
    devices,
    emnify,
    retailers,
    reports,
    categories
  },
})
