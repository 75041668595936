<template>
  <el-menu
    :collapse="isCollapse"
    :collapse-transition="false"
    :default-active="defaultActive"
  >
    <div class="avatar text-center">
      <el-avatar class="app-avatar">YODA</el-avatar>
    </div>
    <router-link style="text-decoration: none; color: inherit" to="/dashboard">
      <el-menu-item index="1">
        <el-icon><home-filled /></el-icon>
        <template #title>Tableau de bord</template>
      </el-menu-item>
    </router-link>
    <router-link style="text-decoration: none; color: inherit" to="/devices">
      <el-menu-item index="2">
        <el-icon><cpu /></el-icon>
        <template #title> Boitiers</template>
      </el-menu-item>
    </router-link>
    <router-link
      style="text-decoration: none; color: inherit"
      to="/organisations"
    >
      <el-menu-item index="3">
        <el-icon><office-building /></el-icon>
        <template #title> Organisations</template>
      </el-menu-item>
    </router-link>
    <!-- <router-link to="/endpoints">
      <el-menu-item index="4">
        <el-icon><box /></el-icon>
        <template #title> EndPoints</template>
      </el-menu-item>
    </router-link> -->
    <router-link style="text-decoration: none; color: inherit" to="/users">
      <el-menu-item index="5">
        <el-icon><user /></el-icon>
        <template #title> Utilisateurs</template>
      </el-menu-item>
    </router-link>
    <router-link
      style="text-decoration: none; color: inherit"
      v-if="isAppUser"
      to="/retailers"
    >
      <el-menu-item index="6">
        <el-icon><user-filled /></el-icon>
        <template #title> Revendeur</template>
      </el-menu-item>
    </router-link>
    <router-link
      style="text-decoration: none; color: inherit"
      v-if="isAppAdmin"
      to="/rapports"
    >
      <el-menu-item index="7">
        <el-icon><pie-chart /></el-icon>
        <template #title> Rapports</template>
      </el-menu-item>
    </router-link>
    <router-link
      style="text-decoration: none; color: inherit"
      v-if="isAppAdmin"
      to="/categories"
    >
      <el-menu-item index="8">
        <el-icon><Reading /></el-icon>
        <template #title> Catégories</template>
      </el-menu-item>
    </router-link>
    <!-- <router-link to="projects">
      <el-menu-item index="3">
        <i class="el-icon-s-cooperation"></i>
        <template #title> Projects</template>
      </el-menu-item>
    </router-link> -->
  </el-menu>
</template>

<script>
import {
  Cpu,
  OfficeBuilding,
  User,
  UserFilled,
  HomeFilled,
  PieChart,
  Reading,
} from '@element-plus/icons-vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  components: {
    Cpu,
    OfficeBuilding,
    User,
    UserFilled,
    HomeFilled,
    PieChart,
    Reading,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['isCollapse']),
    ...mapState('auth', ['token']),
    ...mapGetters({
      isAppUser: 'auth/isAppUser',
      isAppAdmin: 'auth/isAppAdmin',
    }),
    key() {
      return this.$route.path
    },
    // eslint-disable-next-line vue/return-in-computed-property
    defaultActive() {
      if (this.$route.path === '/dashboard') return '1'
      if (
        this.$route.path === '/devices' ||
        this.$route.name == 'device-settings'
      )
        return '2'
      if (this.$route.path === '/organisations') return '3'
      if (this.$route.path === '/endpoints') return '4'
      if (this.$route.path === '/users') return '5'
      if (this.$route.path === '/retailers') return '6'
      if (this.$route.path === '/rapports') return '7'
      if (this.$route.path === '/categories') return '8'
    },
  },
  mounted() {
    let token = localStorage.getItem('token')
    if (token && !this.token) this.$store.commit('auth/SET_TOKEN', token)
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      setOrg: 'organisations/setOrg',
      setUsers: 'users/attempt',
      setOrgs: 'organisations/attempt',
      setmembership: 'organisations/setmembership',
    }),
    setnull() {
      this.setUsers({ data: [], meta: null })
    },
  },
}
</script>

<style>
/* .el-menu--collapse {
  width: 100px !important;
} */
.app-avatar {
  margin-top: 10px;
  margin-bottom: 25px;
  border: 1px solid #34495e;
  background-color: #34495e;
  color: #fff;
}
.el-menu {
  height: 100% !important;
}
.el-menu .el-menu-item.is-active {
  border-right: 2px solid #409eff;
  background-color: rgb(64 158 255 / 10%);
}
</style>
