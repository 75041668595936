<template>
  <div v-loading.fullscreen.lock="false" class="login-container">
    <el-form
      ref="loginForm"
      class="login-form text-center"
      autocomplete="on"
      label-position="left"
      :model="loginForm"
      :rules="rules"
    >
      <span class="login-title">Connexion</span>
          <p v-if="errors.length">
            <ul>
              <div class="alert alert-danger" v-for="(error,index) in errors" :key="index">{{ error }}</div>
            </ul>
          </p>
      <el-form-item prop="email">
        <el-input
          placeholder="nom d'utilisateur ou Email"
          v-model="loginForm.username"
          size="large"
          :prefix-icon="userIcon"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          placeholder="Mot de passe"
          v-model="loginForm.password"
          size="large"
          :prefix-icon="lockIcon"
          type="password"
        ></el-input>
      </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">Connexion</el-button>
           <div class="oublier" >
           <el-button type="text" size="small" @click="handleOpen">
            Mot de passe oublié ?</el-button>
           </div>
    

     <el-dialog
    v-model="dialogVisible"
    title="Saisir votre email"
    width="30%"
    append-to-body
  >
   
    <el-form ref="email" :model="form" :rules="rules">
      <el-form-item  prop="email"
        
        :rules="[
          {
            required: true,
            message: 'Veuillez saisir l\'adresse e-mail',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Veuillez saisir l\'adresse e-mail correcte',
            trigger: ['blur', 'change'],
          },
        ]">
        <el-input v-model="form.email" autocomplete="off" placeholder="Taper votre email"
          :prefix-icon="MessageIcon"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Annuler</el-button>
      
        <el-button type="primary"  @click="envoyer()" > Envoyer </el-button
        >
      </span>
    </template>
  </el-dialog>
        
        </el-form-item>
    </el-form>
     
  </div>

</template>

<script>
import { User, Lock } from '@element-plus/icons-vue'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  data() {
    return {
      dialogVisible: false,

      form: {
        email: null,
        name: null,
        sort_order: null,
      },
      loginForm: {
        username: '',
        password: '',
      },
      errors: [],
      errorss: [],
      valid: true,
      rules: {
        username: [
          {
            required: true,
            message: 'Please input username',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please input password',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    this.setOrg(null)
    this.setUsers({ data: [], meta: null })
    this.setOrgs({ data: [], meta: null })
    this.setmembership([])
  },
  computed: {
    userIcon() {
      return User
    },
    lockIcon() {
      return Lock
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      isBackendUser: 'auth/isBackendUser',
    }),
  },
  methods: {
    handleOpen() {
      this.form.email = null
      this.dialogVisible = true
    },
    ...mapActions({
      login: 'auth/login',
      setOrg: 'organisations/setOrg',
      setUsers: 'users/attempt',
      setOrgs: 'organisations/attempt',
      setmembership: 'organisations/setmembership',
    }),
    submitForm() {
      if (this.loginForm.username == '') {
        this.errors = []
        this.errors.push('Username is required')
        this.valid = false
      } else if (!this.validEmail(this.loginForm.username)) {
        this.errors = []
        this.errors.push('Username should be a valid email')
        this.valid = false
      } else if (this.loginForm.password == '') {
        this.errors = []
        this.errors.push('Password is required')
        this.valid = false
      } else {
        this.valid = true
        this.errors = []
      }
      if (this.valid) {
        let data = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          client_id: '1',
          client_secret: 'l51QXbqPYEZTVgnTyjWW6VfqTIhojkqEpd7f8gnV',
          grant_type: 'password',
          scope: '',
        }

        this.login(data)
          .then(() => {
            if (!this.isBackendUser) return
            let appRole = _.get(this.user, 'appRole', null)
            let roles = _.get(this.user, 'roles', [])
            if (_.includes(roles, appRole)) {
              this.$router.push('/dashboard')
            }
          })
          .catch((errors) => {
            if (errors.response.data.errors) {
              Object.values(errors.response.data.errors).forEach((element) => {
                this.errors.push(element.toString())
              })
            } else {
              this.errors.push(errors.response.data.error.toString())
            }
          })
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    envoyer() {
      if (this.form.email) {
        this.$store
          .dispatch('auth/resetPassword', {
            data: {
              email: this.form.email,
            },
          })
          .then(() => {
            this.dialogVisible = false
            this.isSent = true
          })
          .catch(() => {
            console.log('error')
          })
          .finally(() => {
            this.isLoaded = false
          })
      }
    },
  },
}
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}
.login-title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
}
.login-form {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80vh;
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
}
.oublier {
  margin-left: 109px;
}

.register {
  margin-left: 20px;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .login-form {
    width: 80vw;
  }
}
@media only screen and (min-width: 1200px) {
  .login-form {
    width: 25vw;
  }
}
</style>
