import _ from 'lodash'
import request from '@/utils/request'

import { getField, updateField } from 'vuex-map-fields'
export default {
  namespaced: true,
  state: {
    loading: false,
    organisations: [],
    memberships: [],
    members: [],
    organisation: null,
    metadata: null,
    filter: {
      retailerId: null,
      q: null,
      page: 0,
    },
  },
  getters: {
    getField,
    organisations(state) {
      return state.organisations
    },
    memberships(state) {
      return state.memberships
    },
    organisation(state) {
      return state.organisation
    },
    metadata(state) {
      return state.metadata
    },
  },
  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading;
    },
    updateField,
    SET_ORGANISATIONS(state, organisations) {
      state.organisations = organisations
    },
    SET_MEMBERSHIPS(state, memberships) {
      state.memberships = memberships
    },
    SET_MEMBERS(state, members) {
      state.members = members
    },
    SET_ORGANISATION(state, org) {
      state.organisation = org
    },
    ADD_ORGANISATION(state, data) {
      state.organisations.push(data)
    },
    ASSIGN_ORGANISATION(state, data) {
      let index = state.organisations.findIndex((item) => item.id == data.id)
      if (index != -1) Object.assign(state.organisations[index], data)
    },
    REMOVE_ORGANISATION(state, id) {
      let index = state.organisations.findIndex((item) => item.id == id)
      if (index != -1) state.organisations.splice(index, 1)
    },
    SET_META(state, meta) {
      state.metadata = meta
    },
  },
  actions: {
    getOrganisations({ dispatch }, { params = {}, headers = {}, data = {} }) {
      let defaultParams = {
        sort: 'id,asc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/admin/organizations`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          dispatch('attempt', response.data)
          return response.data.content
        })
        .catch((error) => console.log(error))
    },

 
    getMembers({ commit }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/admin/members`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('SET_MEMBERS', response.data)
          return response
        })
        .catch((error) => console.log(error))
    },


    addMember({ dispatch }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/admin/members`,
        method: 'post',
        params,
        headers,
        data,
      })
        .then((response) => {
          dispatch('getMembers', {
            params: { organizationId: data.organization.id },
          })
          return response
        })
        .catch((error) => console.log(error))
    },

    updateMember(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/admin/members/${id}`,
        method: 'put',
        params,
        headers,
        data,
      })
        .then((response) => {
          dispatch('getMembers', {
            params: { organizationId: data.organization.id },
          })
          return response
        })
        .catch((error) => console.log(error))
    },

   
    deleteMember(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/admin/members/${id}`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then((response) => {
          dispatch('getMembers', {
            params: { organizationId: data.organizationId },
          })
          return response
        })
        .catch((error) => console.log(error))
    },
    attempt({ commit }, data) {
      commit('SET_ORGANISATIONS', data.content)
      commit('SET_META', {
        pageable: data.pageable,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
      })
    },

    updateOrganisation(
      { commit },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/admin/organizations/${id}`,
        method: 'put',
        params,
        headers,
        data,
      }).then((response) => {
        commit('ASSIGN_ORGANISATION', response.data)
        return response
      })
    },

    deleteOrg({ commit }, { id = null, params = {}, headers = {}, data = {} }) {
      return request({
        url: `/admin/organizations/${id}`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then(() => {
          commit('REMOVE_ORGANISATION', data.id)

        })
        .catch((error) => console.log(error))
    },

  
    addorg({ commit }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/admin/organizations`,
        method: 'post',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('ADD_ORGANISATION', response)
        })
        .catch((error) => console.log(error))
    },

    setOrg({ commit }, data) {
      commit('SET_ORGANISATION', data)
    },
    setmembership({ commit }, data) {
      commit('SET_MEMBERSHIPS', data)
    },

  },
}
