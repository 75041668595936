import axios from 'axios'
import store from '@/store'
import TokenService from '../service/tokenService'
import { ElMessage } from 'element-plus'
import _ from 'lodash'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,

  timeout: 30000,
})

service.interceptors.request.use(
  (config) => {

    if (store.state.auth.token) {
      config.headers['Authorization'] = store.state.auth.token
    }
    return config
  },
  (error) => {

    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.config.method != 'get') {
      ElMessage({
        message: 'Opération réussie.',
        type: 'success',
      })
    }
    return response
  },
  async (error) => {
    const originalConfig = error.config;
    if (401 === error.response.status &&
      !originalConfig._retry) {

      originalConfig._retry = true;
      try {
        const res = await
          service.post("/auth/refresh-token",
            { refreshToken: TokenService.getLocalRefreshToken() });

        store.commit('auth/SET_TOKEN', 'Bearer ' + res.data.token)
        store.commit('auth/SET_USER', res.data)
        // if (res.data.organizationId == null) {
        //   window.location = '/dashboard'
        // }
        return service(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }

    } else {
      if (200 !== error.response.status
        && originalConfig.url == "/auth/refresh-token") {
        ElMessage.error("l'opération a échoué")
        window.location = '/login'
      }
      else {
        ElMessage.error(_.get(
          error,
          'response.data.error[0]',
          'Un problème est survenu'
        ))
        return Promise.reject(error)
      }

    }
  }
)

export default service

