import _ from 'lodash'
import request from '@/utils/request.js'
import store from '@/store'

export default {
  namespaced: true,
  state: {

    token: null,
    user: null,
    member: null,
    roleOptions: [
      { value: 'APP_ADMIN', label: 'App_Admin' },
      { value: 'APP_USER', label: 'App_User' },
      { value: 'RETAILER_ADMIN', label: 'Revendeur_Admin' },
      { value: 'RETAILER_MANAGER', label: 'Revendeur_Manager' },
      { value: 'null', label: 'Aucun rôle' },
    ],
  },
  getters: {
    authenticated(state) {
      return state.user && state.token
    },
    user(state) {
      return state.user
    },
    token(state) {
      return state.token
    },
    userRole(state) {
      let role = state.user?.appRole ?? null
      if (!role) return null
      let obj = _.find(state.roleOptions, { value: role })
      if (obj) return obj.label

      return null
    },
    roleOptions(state) {
      return state.roleOptions
    },
    member(state) {
      return state.member
    },
    isAppAdmin(state) {
      return _.includes(_.get(state, 'user.roles', []), 'APP_ADMIN')
    },
    isadmin(state) {
      return _.includes(_.get(state, 'user.enabled', []), 'true')
    },
    isAppUser(state) {
      return _.intersection(_.get(state, 'user.roles', []), [
        'APP_ADMIN',
        'APP_USER',
      ]).length
    },
    isAdmin(state) {
      return _.includes(_.get(state, 'user.roles', []), 'RETAILER_ADMIN')
    },
    isManager(state) {
      return _.includes(_.get(state, 'user.roles', []), 'RETAILER_MANAGER')
    },
    isRetailerUser(state) {
      return _.intersection(_.get(state, 'user.roles', []), [
        'RETAILER_ADMIN',
        'RETAILER_MANAGER',
      ]).length
    },
    isBackendUser(state) {
      return _.intersection(_.get(state, 'user.roles', []), [
        'APP_ADMIN',
        'APP_ADMIN',
        'RETAILER_ADMIN',
        'RETAILER_MANAGER',
      ]).length
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    },
    SET_USER(state, data) {
      state.user = data
      if (data) {
        localStorage.setItem('user', JSON.stringify(data))
        localStorage.setItem('refreshToken', data.refreshToken)
      } else {
        localStorage.removeItem('user')
      }
    },
  },
  actions: {
    login({ dispatch }, credentials) {
      let data = {
        email: credentials.username,
        password: credentials.password,
      }
      return request({
        url: `/auth/login`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((response) => {
          dispatch('attempt', response.data)
          return (response)
        })
        .catch((error) => console.log(error))

    },

    async attempt({ commit }, response) {
      if (response.token) {
        commit('SET_TOKEN', 'Bearer ' + response.token)
        commit('SET_USER', response)
      } else {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },

    logout({ commit }) {
      let headers = {}
      if (store.getters['auth/token']) {
        headers.Authorization = store.getters['auth/token'] //getToken()
      }
      return request({
        url: `/users/logout`,
        method: 'POST',
        headers,
        data: {},
      })
        .then((response) => {
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
          return (response)
        })
        .catch((error) => console.log(error))

    },

    resetPassword({ commit }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/auth/reset-password`,
        method: 'post',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('')
          return response
        })
        .catch((error) => error)
    },
  },
}
