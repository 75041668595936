import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'

import Layout from '../layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    alias: '/dashboard',
    redirect: { name: 'dashboard' },
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'dashboard',
        meta: {
          title: 'Tableau de bord',
          breadcrump: [{ text: 'Tableau de bord' }],
          icon: 'el-icon-s-home',
          affix: true,
        },
      },

 
      {
        path: 'devices',
        redirect: { name: 'devices' },
        children: [
          {
            path: '/devices',
            component: () => import('@/views/devices/index'),
            name: 'boitiers',
            meta: {
              title: 'boitiers',
              breadcrump: [{ text: 'Tableau de bord' }, { text: 'Boitier' }],
            },
          },
          {
            path: ':id/parametres',
            component: () => import('@/views/devices/DeviceSettings'),
            name: 'device-settings',
            meta: {
              title: 'Paramètres',
              breadcrump: [{ text: 'Tableau de bord', path: '/dashboard' }, { text: 'Boitier', path: '/devices' }, { text: '__ID__', path: '/devices/__ID__/parametres' }, { text: 'Paramètres' }],

            },
          },
        ]
      },

      {
        path: '/organisations',
        redirect: { name: 'organisations' },
        children: [
          {
            path: '/organisations',
            component: () => import('@/views/organisations/index'),
            name: 'organisations',
            meta: {
              title: 'organisations',
              breadcrump: [{ text: 'Tableau de bord' }, { text: 'organisations' }],
              icon: 'el-icon-s-home',

            },
          },
          {
            path: ':id/membres',
            component: () => import('@/views/members/index'),
            name: 'OrgMembers',
            meta: {
              title: 'membres',
              breadcrump: [{ text: 'Tableau de bord', path: '/dashboard' }, { text: 'organisations', path: '/organisations' }, { text: '__ID__', path: '/organisations/__ID__/membres' }, { text: 'members' }],
              icon: 'el-icon-s-home',

            },
          },
        ]
      },

      {
        path: 'users',
        component: () => import('@/views/users/index'),
        name: 'users',
        meta: {
          title: 'utilisateurs',
          breadcrump: [{ text: 'Tableau de bord' }, { text: 'utilisateurs' }],
          icon: 'el-icon-user',
          affix: true,
        },
      },

      {
        path: 'retailers',
        component: () => import('@/views/retailers/index'),
        name: 'retailers',
        meta: {
          title: 'Revendeurs',
          breadcrump: [{ text: 'Tableau de bord' }, { text: 'Revendeurs' }],
          icon: 'el-icon-user',
          affix: true,
        },
      },
      {
        path: 'rapports',
        component: () => import('@/views/reports/index'),
        name: 'Reports',
        meta: {
          title: 'Rapports',
          breadcrump: [{ text: 'Rapports' }, { text: 'Rapports' }],
        },
      },

      {
        path: 'categories',
        component: () => import('@/views/categories/index'),
        name: 'Categories',
        meta: {
          title: 'Categories',
          breadcrump: [{ text: 'Categories' }, { text: 'categories' }],
        },
      },
      {
        path: '/account',
        component: () => import('@/views/account/index'),
        name: 'Account',
        meta: {
          title: 'mon compte',
          breadcrump: [{ text: 'Tableau de bord' }, { text: 'Mon compte' }],
        },
      },
      {
        path: 'endpoints',
        component: () => import('@/views/endpoints/EndpointDashboard'),
        name: 'endpoints',
        meta: {
          title: 'endpoints',
          breadcrump: [{ text: 'Tableau de bord' }, { text: 'endpoints' }],
        },
      },
      {
        path: 'boitierDetails',
        component: () => import('@/views/devices/components/detailsboitier'),
        name: 'boitierDetails',
        meta: { title: 'boitierDetails' },
      },
      {
        path: 'account',
        component: () => import('@/views/account/index'),
        name: 'account',
        meta: {
          title: 'Compte',
          breadcrump: [{ text: 'Tableau de bord' }, { text: 'Compte' }],
          icon: 'el-icon-user',
          affix: true,
        },
      },


    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },

  {
    path: '/about',
    name: 'About',

    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
