<template>
  <el-footer>
    <div class="footer-text">&#169; ODACEL</div>
  </el-footer>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: true,
    }
  },
}
</script>

<style>
.el-footer {
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-text {
  font-size: 14px;
}
</style>
