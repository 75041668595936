
export function parseToJson(form) {
    for (var key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
            let field = null
            try {
                field = form[key] != null ? JSON.parse(form[key]) : null
            } catch (e) {
                field = form[key]
            } finally {
                form[key] = field
            }
        }
    }

    return form
}

export function stringifyJson(form) {
    for (var key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
            let field = null
            try {
                field = form[key] != null ? JSON.stringify(form[key]) : null
            } catch (e) {
                field = form[key]
            } finally {
                form[key] = field
            }
        }
    }

    return form
}
