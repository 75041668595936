import request from '../../utils/request.js'
import { parseToJson } from '../../helpers/ParseStringifyJson'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    isLoaded: false,
    reportClasses: [],
  },
  getters: {
    reportClasses(state) {
      return state.reportClasses
    },
  },
  mutations: {
    setList(state, data) {
      state.list = data
    },
    setClasses(state, data) {
      state.reportClasses = data
    },
  },
  actions: {
    getList({ commit }, { params = {}, headers = {} }) {
      let defaultParams = {
        sort: 'id,desc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/reports`,
        method: 'get',
        params,
        headers,
      }).then((response) => {
        commit('setList', response.data)
        return response.data
      })
    },
   
      addItem({ dispatch }, { params = {}, headers = {}, data = {} }) {
          return request({
            url: `/reports`,
            method: 'post',
            params,
            data,
            headers,
          }).then(() => {
            dispatch('getList', {})
          })
        },

    updateItem(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      data = parseToJson(data)
      return request({
        url: `/reports/${id}`,
        method: 'put',
        params,
        data,
        headers,
      }).then(() => {
        dispatch('getList', {})
      })
    },
    deleteItem(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/reports/${id}`,
        method: 'delete',
        params,
        data,
        headers,
      }).then(() => {
        dispatch('getList', {})
      })
    },
    getClasses({ commit }, { params = {}, headers = {} }) {
      return request({
        url: `/reports/classes`,
        method: 'get',
        params,
        headers,
      }).then((response) => {
        commit('setClasses', response.data)
        return response.data
      })
    },
  },
}
