<template>
  <el-header class="d-flex justify-content-between">
    <div class="button">
      <el-icon circle size="sm" @click="toggleSidebare"><Menu /></el-icon>
      <br />
      <div class="bred">
        <breadcrumb />
      </div>
    </div>
    <div>
      <UserAccount />
    </div>
  </el-header>
</template>

<script>
import { Menu } from '@element-plus/icons-vue'
import Breadcrumb from '../components/Breadcrumb.vue'
import UserAccount from '../components/UserAccount.vue'
export default {
  components: { Breadcrumb, UserAccount, Menu },
  data() {
    return {}
  },
  computed: {},

  methods: {
    toggleSidebare() {
      this.$store.state.isCollapse = !this.$store.state.isCollapse
    },
  },
}
</script>

<style>
.button {
  display: flex;
}
.bred {
  margin-top: 2px;
  margin-left: 12px;
}
</style>
