<template>
  <el-button type="text" @click="centerDialogVisible = true">
    <div class="d-flex align-items-center">
      <el-avatar
        :size="30"
        style="background-color: #f56c6c; margin-right: 10px;font-weight"
        >{{ avatar }}</el-avatar
      >
      <span v-if="authenticated" style="font-size: 15px; color: black">{{
        user.fullname
      }}</span>
    </div>
  </el-button>
  <el-dialog v-model="centerDialogVisible" width="25%" center>
    <div class="text-center">
      <el-avatar
        :size="100"
        style="background-color: #f56c6c; margin-right: 10px; font-size: 60px"
        >{{ avatar }}</el-avatar
      >
      <br />
      <span v-if="authenticated" style="font-size: 20px; color: black">{{
        user.fullname
      }}</span
      ><br />
      <span v-if="authenticated" style="font-size: 15px; color: grey">{{
        user.email
      }}</span>
      <br /><br />
      <el-tag size="small">{{ userRole }}</el-tag>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-space>
          <router-link to="/account">
            <el-button @click="centerDialogVisible = false"
              >Mon compte</el-button
            >
          </router-link>
          <el-button type="danger" @click="logout()">Déconnexion</el-button>
        </el-space>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      centerDialogVisible: false,
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      userRole: 'auth/userRole',
    }),
    avatar() {
      return this.user?.fullname?.charAt(0) ?? 'X'
    },
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
    }),
    logout() {
      this.logoutAction().then(() => {
        this.$router.push('/login')
      })
    },
  },
}
</script>

<style>
.change-position {
  position: absolute;
  right: 0px;
}
</style>
